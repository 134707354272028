



























// eslint-disable-next-line import/extensions
import { BooksResponse, Books } from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'

export default {
  name: 'BookList',
  data() {
    return {
      loading: false,
      selectedItem: -1,
      items: [] as Books[],
    }
  },
  beforeMount() {
    this.fetchBooks()
  },
  methods: {
    bookPath(book: Books) {
      return `/book/${book.book_id}`
    },
    /**
     * Call books api
     */
    fetchBooks() {
      return new Promise((resolve, reject) => {
        this.loading = true
        fetch(Urls.url('books'), {
          method: 'GET',
          headers: { Authorization: localStorage.tk },
        }).then((response: Response) => response.json())
          .then((books: BooksResponse) => {
            this.loading = false
            if (books.status !== 'Success') {
              reject(books.message)
            } else {
              this.items = books.data || []
              resolve(true)
            }
          })
          .catch((err: string) => {
            Errors.logError(err)
            reject()
          })
      })
    },
  },
}
