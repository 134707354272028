


















































import Display from '@/utilities/Display'
import InsideContainer from '@/components/Containers/InsideContainer.vue'
import BookList from '@/components/Lists/BookList.vue'
import UploadsList from '@/components/Lists/UploadsList.vue'
import Loading from '@/components/Modals/Loading.vue'

export default {
  name: 'List',
  components: {
    InsideContainer,
    BookList,
    UploadsList,
    Loading,
  },
  data() {
    return {
      listType: '',
      childLoaded: true,
    }
  },
  computed: {
    mobile(): boolean { return Display.mobile(this.$vuetify) },
  },
  beforeMount() {
    this.listType = this.$route.params.list
  },
  methods: {
    childLoading(loading) {
      this.childLoaded = loading
    },
  },
}
