



























// eslint-disable-next-line import/extensions
import { UploadsResponse, UploadListing } from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'

export default {
  name: 'UploadsList',
  data() {
    return {
      loading: false,
      selectedItem: -1,
      items: [] as UploadListing[],
    }
  },
  beforeMount() {
    this.fetchAuth()
      .then(() => {
        this.$emit('loading', false)
      })
      .catch(() => {
        this.$emit('loading', false)
      })
  },
  methods: {
    uploadPath(upload: UploadListing) {
      return `/upload/${upload.upload_id}`
    },
    /**
     * Call uploads api
     */
    fetchAuth() {
      return new Promise((resolve, reject) => {
        this.loading = true
        fetch(Urls.url('uploads'), {
          method: 'GET',
          headers: { Authorization: localStorage.tk },
        }).then((response: Response) => response.json())
          .then((uploads: UploadsResponse) => {
            this.loading = false
            if (uploads.status !== 'Success') {
              reject(uploads.message)
            } else {
              this.items = uploads.data || []
              resolve(true)
            }
          })
          .catch((err: string) => {
            Errors.logError(err)
            reject()
          })
      })
    },
  },
}
